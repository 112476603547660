const CODE_OK = 'OK';
const CODE_NOK = 'NOK';
const COMPANY_TAX_ID = 'RZP081105ID0';
const INTEGER_ACCEPTED_RANGE_WHEN_INVOICING = 1;
const DECIMAL_ACCEPTED_RANGE_WHEN_INVOICING = 99;

module.exports = {
    CODE_OK,
    CODE_NOK,
    COMPANY_TAX_ID,
    INTEGER_ACCEPTED_RANGE_WHEN_INVOICING,
    DECIMAL_ACCEPTED_RANGE_WHEN_INVOICING
};
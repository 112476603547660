<template>
    <b-overlay :show="isLoading">
        <b-card>
            <b-row>
                <b-col>
                    <div class="float-left">
                        <b-badge style="font-size: 1.2em; width: 200px" variant="warning" class="p-1" v-if="invoice.status === 'TO_VALIDATE'">{{ $t('toValidate') }}</b-badge>
                        <b-badge style="font-size: 1.2em; width: 200px" variant="info" class="p-1" v-if="invoice.status === 'IN_PROCESS'">{{ $t('inProcess') }}</b-badge>
                        <b-badge style="font-size: 1.2em; width: 200px" variant="success" class="p-1" v-if="invoice.status === 'PAID'">{{ $t('paid') }}</b-badge>
                        <b-badge style="font-size: 1.2em; width: 200px" variant="danger" class="p-1" v-if="invoice.status === 'CANCELLED'">{{ $t('cancelled') }}</b-badge>
                        <b-badge style="font-size: 1.2em; width: 200px" variant="danger" class="p-1" v-if="invoice.status === 'REJECTED'">{{ $t('rejected') }}</b-badge>
                    </div>
                    <div class="float-right">
                        <b-button type="button" variant="outline-primary" class="ml-4" :to="{name: 'Invoices'}">
                            <font-awesome-icon icon="angle-left"></font-awesome-icon> {{ $t('back') }}
                        </b-button>
                        <template v-if="user.id > 0 && user.verified && user.status === 'APPROVED'">
                            <b-button type="button" variant="outline-primary" class="ml-4" @click="edit" v-if="!isEditing && invoice.status === 'REJECTED'">
                                <font-awesome-icon icon="edit"></font-awesome-icon> {{ $t('edit') }}
                            </b-button>
                            <b-button type="button" variant="outline-primary" class="ml-4" @click="edit" v-if="isEditing">
                                <font-awesome-icon icon="times"></font-awesome-icon> {{ $t('cancel') }}
                            </b-button>
                            <b-button type="button" variant="outline-primary" class="ml-4" @click="update" v-if="isEditing"
                                      :disabled="!amounts.canInvoice || amounts.amountExceeded">
                                <font-awesome-icon icon="save"></font-awesome-icon> {{ $t('update') }}
                            </b-button>
                        </template>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4" style="margin-bottom: -20px">
                <b-col>
                    <b-alert v-show="invoice.rejectedNotes && invoice.status === 'REJECTED'" show variant="warning" style="background: #fff3cd">
                        <strong>{{ $t('reason') }}:</strong> <br>
                        {{ invoice.rejectedNotes }}
                    </b-alert>
                </b-col>
            </b-row>
            <b-row class="mt-4" v-if="!invoice.requiresXmlFile && isEditing" style="margin-bottom: -20px">
                <b-col>
                    <b-alert v-show="invoice.purchaseOrder !== '' && !amounts.isSameCurrency" show variant="warning"
                             style="background: #FFEDC8">
                        {{ $t('purchaseOrderCurrencyPart1Alert') }} (<strong>{{ amounts.currencyPO }}</strong>)
                        {{ $t('purchaseOrderCurrencyPart2Alert') }} (<strong>{{ invoice.currency }}</strong>)
                    </b-alert>
                    <b-alert show :variant="((!amounts.canInvoice && amounts.amountExceeded) || (!amounts.canInvoice && !amounts.amountExceeded)) ? 'dark' : 'info'"
                             :style="((!amounts.canInvoice && amounts.amountExceeded) || (!amounts.canInvoice && !amounts.amountExceeded)) ? 'background: #f8d7da' : ''">
                        <p v-if="!amounts.canInvoice && !amounts.amountExceeded">{{ $t('purchaseOrderInvoicedAlert') }}</p>
                        <p v-if="!amounts.canInvoice && amounts.amountExceeded">{{ $t('amountExceeded') }}</p>
                        
                        <b-row>
                            <b-col class="col-2">
                                <p><strong>{{ $t('purchaseOrderAmountLabel') }}:</strong></p>
                                <p><strong>{{ $t('amountInvoicedLabel') }}:</strong></p>
                                <p><strong>{{ $t('invoiceAmountLabel') }}:</strong></p>
                            </b-col>
                            <b-col class="col-1 text-right">
                                <p>$ {{ formatMoney(amounts.purchaseTotal, 2, [',',',','.']) }} {{ amounts.currencyPO }}</p>
                                <p>$ {{ formatMoney(amounts.invoicedTotal, 2, [',',',','.']) }} {{ amounts.currencyPO }}</p>
                                <p>$ {{ formatMoney(exchangeRate(invoice.total), 2, [',',',','.']) }} {{ amounts.currencyPO }}</p>
                            </b-col>
                        </b-row>
                    </b-alert>
                </b-col>
            </b-row>
            <template v-if="!invoice.requiresXmlFile">
                <b-row class="mt-4">
                    <b-col>
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th>PDF/IMG:</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="background: #fafbfe">
                                <td class="p-md-2">
                                    <b-button type="button" variant="white" v-if="!isEditing"
                                              @click="downloadXmlPdfF(invoice.pdfFileEncryptedName, invoice.pdfFile)">
                                        <font-awesome-icon icon="download"></font-awesome-icon>&nbsp;&nbsp; {{ invoice.pdfFile }}
                                    </b-button>
                                    <b-form-group class="mb-2 text-black" v-if="isEditing" style="margin-top: -5px">
                                        <b-form-file v-model="file" type="file" accept=".pdf" size="md"
                                                     :placeholder="dataCache.fileName" :browse-text="$t('select')"></b-form-file>
                                    </b-form-group>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th :style="(invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing) ? 'width: 20%' : 'width: 25%'">{{ $t('invoiceDate') }}</th>
                                <th :style="(invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing) ? 'width: 20%' : 'width: 25%'">{{ $t('invoiceNumber') }}</th>
                                <th :style="(invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing) ? 'width: 20%' : 'width: 25%'">{{ $t('amount') }}</th>
                                <th :style="(invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing) ? 'width: 20%' : 'width: 25%'">{{ $t('currency') }}</th>
                                <th :style="(invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing) ? 'width: 20%' : ''" v-if="invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing">
                                    {{ $t('exchangeRate') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="background: #fafbfe">
                                <td class="p-md-2" :class="!isValid.invoiceDate ? 'has-error' : ''">
                                    <span v-if="!isEditing">{{ invoice.invoiceDate }}</span>
                                    <b-form-datepicker v-model="invoice.invoiceDate" v-if="isEditing" required boundary="window"
                                                       :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                                    <span style="color: #d26869" v-if="!isValid.invoiceDate">{{ $t('requiredField') }}</span>
                                </td>
                                <td class="p-md-2" :class="!isValid.invoiceNumber ? 'has-error' : ''">
                                    <span v-if="!isEditing">{{ invoice.invoiceNumber }}</span>
                                    <b-form-group v-if="isEditing" class="mb-auto mt-auto">
                                        <b-form-input type="text" required
                                                      v-model="invoice.invoiceNumber" @input="validateFields"></b-form-input>
                                    </b-form-group>
                                    <span style="color: #d26869" v-if="!isValid.invoiceNumber">{{ $t('requiredField') }}</span>
                                </td>
                                <td class="p-md-2" :class="!isValid.amount ? 'has-error' : ''">
                                    <span v-if="!isEditing">{{ formatMoney(invoice.total, 2, [',',',','.']) }}</span>
                                    <b-form-group v-if="isEditing" class="mb-auto mt-auto">
                                        <b-form-input type="text" v-model="invoice.total" @input="validateFields" required></b-form-input>
                                    </b-form-group>
                                    <span style="color: #d26869" v-if="!isValid.amount">{{ $t('notValueValid') }}</span>
                                </td>
                                <td class="p-md-2" :class="!isValid.currency ? 'has-error' : ''">
                                    <span v-if="!isEditing">{{ invoice.currency }}</span>
                                    <b-form-group v-if="isEditing" class="mb-auto mt-auto">
                                        <select id="currency-select" required><option value=""></option></select>
                                    </b-form-group>
                                    <span style="color: #d26869" v-if="!isValid.currency">{{ $t('requiredField') }}</span>
                                </td>
                                <td class="p-md-2" :class="!isValid.exchangeRate ? 'has-error' : ''" v-if="invoice.purchaseOrder !== '' && !amounts.isSameCurrency && isEditing">
                                    <b-form-group v-if="isEditing" class="mb-auto mt-auto">
                                        <b-form-input type="text" v-model="invoice.exchangeRate" @input="validateFields" required></b-form-input>
                                    </b-form-group>
                                    <span style="color: #d26869" v-if="!isValid.exchangeRate">{{ $t('notValueValid') }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <b-row class="mt-4">
                    <b-col>
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th style="width: 50%">XML</th>
                                <th style="width: 50%">PDF/IMG</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="background: #fafbfe">
                                <td class="p-md-2">
                                    <b-button type="button" variant="white"
                                              @click="downloadXmlPdfF(invoice.xmlFileEncryptedName, invoice.xmlFile)">
                                        <font-awesome-icon icon="download"></font-awesome-icon>&nbsp;&nbsp; {{ invoice.xmlFile }}
                                    </b-button>
                                </td>
                                <td class="p-md-2">
                                    <b-button type="button" variant="white" v-if="!isEditing"
                                              @click="downloadXmlPdfF(invoice.pdfFileEncryptedName, invoice.pdfFile)">
                                        <font-awesome-icon icon="download"></font-awesome-icon>&nbsp;&nbsp; {{ invoice.pdfFile }}
                                    </b-button>
                                    <b-form-group class="text-black" v-if="isEditing" style="margin-top: -5px">
                                        <b-form-file v-model="file" type="file" accept=".pdf" size="md"
                                                     :placeholder="dataCache.fileName" :browse-text="$t('select')"></b-form-file>
                                    </b-form-group>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th style="width: 25%">{{ $t('serial') }}</th>
                                <th style="width: 25%">{{ $t('folio') }}</th>
                                <th style="width: 25%">{{ $t('invoiceDate') }}</th>
                                <th style="width: 25%">{{ $t('amount') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="background: #fafbfe">
                                <td class="p-md-2">{{ invoice.serial }}</td>
                                <td class="p-md-2">{{ invoice.folio }}</td>
                                <td class="p-md-2">{{ invoice.invoiceDate }}</td>
                                <td class="p-md-2">{{ formatMoney(invoice.total, 2, [',',',','.']) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row style="margin-top: -15px">
                    <b-col>
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th style="width: 25%">{{ $t('currency') }}</th>
                                <th style="width: 25%">{{ $t('paymentMethod') }}</th>
                                <th style="width: 25%">{{ $t('paymentWay') }}</th>
                                <th style="width: 25%">{{ $t('useCFDI') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="background: #fafbfe">
                                <td class="p-md-2">{{ invoice.currency }}</td>
                                <td class="p-md-2">{{ invoice.paymentMethod }}</td>
                                <td class="p-md-2">{{ invoice.paymentWay }}</td>
                                <td class="p-md-2">{{ invoice.CFDI }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row style="margin-top: -15px">
                    <b-col>
                        <table class="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th style="width: 50%">{{ $t('voucherType') }}</th>
                                <th style="width: 50%">{{ $t('uuid') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="background: #fafbfe">
                                <td class="p-md-2">{{ invoice.voucherType }}</td>
                                <td class="p-md-2">{{ invoice.UUID }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </template>
            <b-row style="margin-top: -15px">
                <b-col>
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th style="width: 25%">{{ $t('issuingTaxId') }}</th>
                            <th style="width: 25%">{{ $t('issuingName') }}</th>
                            <th style="width: 25%">{{ $t('recipientTaxId') }}</th>
                            <th style="width: 25%">{{ $t('recipientName') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="background: #fafbfe">
                            <td class="p-md-2">{{ invoice.issuingTaxId }}</td>
                            <td class="p-md-2">{{ invoice.issuingName }}</td>
                            <td class="p-md-2">{{ invoice.recipientTaxId }}</td>
                            <td class="p-md-2">{{ invoice.recipientName }}</td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row style="margin-top: -15px" v-if="invoice.estimatedPaymentDate">
                <b-col>
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th style="width: 25%">{{ $t('estimatedPaymentDate') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="background: #fafbfe">
                            <td class="p-md-2">{{ invoice.estimatedPaymentDate }}</td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>{{ $t('notes') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="background: #fafbfe">
                            <td class="p-md-2">
                                <p class="mt-2" v-if="!isEditing">{{ invoice.notes ? invoice.notes : $t('noRecord')}}</p>
                                <b-form-group class="text-black" v-if="isEditing">
                                    <b-form-textarea rows="3" no-auto-shrink v-model="notes"></b-form-textarea>
                                </b-form-group>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import { currencyList, downloadFile, invoiceShow, invoiceUpdate, validatePurchaseOrder } from '@routes';
import * as constants from '@constants';
import { downloadAttachment, formatMoney } from '@/js/utilities';
import $ from 'jquery';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            isLoading: false,
            invoiceId: 0,
            breadcrumb: {
                title: this.$t('invoices'),
                path: []
            },
            user: {
                id: 0,
                name: 'User Name',
                email: '',
                username: '',
                roles: '',
                verified: false,
                status: '',
                supplierId: 0,
                supplierLegalName: '',
                reason: ''
            },
            invoice: {
                id: 0,
                status: 'VALID',
                folio: '',
                invoiceDate: '',
                total: 0.00,
                exchangeRate: '',
                currency: '',
                currencyId: '',
                issuingName: '',
                issuingTaxId: '',
                recipientName: '',
                recipientTaxId: '',
                purchaseOrder: '',
                salesOrder: '',
                supplierToken: '',
                serial: '',
                paymentMethod: '',
                paymentWay: '',
                voucherType: '',
                CFDI: '',
                UUID: '',
                certificateDate: '',
                action: '',
                validationStatus: 'PENDING',
                notes: '',
                validationCodeValidated: '',
                validationCode: '',
                dollarPrice: '',
                euroPrice: '',
                warehouseReceiptToken: '',
                warehouseReceiptValidationStatus: '',
                warehouseReceiptValidated: '',
                requiresXmlFile: true,
                invoiceNumber: '',
                xmlFile: '',
                pdfFile: '',
                xmlFileEncryptedName: '',
                pdfFileEncryptedName: '',
                rejectedNotes: '',
                estimatedPaymentDate: ''
            },
            isEditing: false,
            dataCache: {
                fileName: '',
                notes: '',
                invoiceDate: '',
                invoiceNumber: '',
                currency: '',
                amount: '',
                exchangeRate: ''
            },
            isValid: {
                invoiceDate: true,
                invoiceNumber: true,
                currency: true,
                exchangeRate: true,
                amount: true
            },
            notes: '',
            file: { name: '' },
            amounts: {
                purchaseTotal: 0.00,
                invoicedTotal: 0.00,
                invoicedSum: 0.00,
                creditTotal: 0.00,
                currencyPO: 'USD',
                canInvoice: true,
                isSameCurrency: true,
                amountExceeded: false
            }
        };
    },
    mounted() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.user = JSON.parse(localStorage.getItem('user'));
        this.invoiceId = localStorage.getItem('invoiceId');
        
        if (parseInt(this.invoiceId) > 0) {
            this.axios.get(invoiceShow(this.invoiceId)).then(response => {
                if (response.data.code === 'OK') {
                    this.invoice = response.data.invoice;
                    this.$forceUpdate();
                } else {
                    this.$router.push({ name: 'Home' });
                }
            });
        }
    },
    methods: {
        formatMoney: formatMoney,
        edit() {
            this.isEditing = !this.isEditing;
            
            if (this.isEditing) {
                this.dataCache.fileName = this.invoice.pdfFile;
                this.dataCache.notes = this.invoice.notes;
                this.notes = this.invoice.notes;
                this.file.name = this.invoice.pdfFile;
                
                if (!this.invoice.requiresXmlFile) {
                    this.dataCache.invoiceDate = this.invoice.invoiceDate;
                    this.dataCache.invoiceNumber = this.invoice.invoiceNumber;
                    this.dataCache.currency = this.invoice.currency;
                    this.dataCache.amount = this.invoice.total;
                    this.dataCache.exchangeRate = this.invoice.exchangeRate;
                    
                    this.validatePurchaseOrderCode();
                    
                    this.$nextTick(() => {
                        this.initSelect2();
                    });
                }
            } else {
                this.invoice.pdfFile = this.dataCache.fileName;
                this.invoice.notes = this.dataCache.notes;
                this.dataCache.fileName = '';
                this.dataCache.notes = '';
                this.notes = '';
                this.file.name = '';
                
                if (!this.invoice.requiresXmlFile) {
                    this.invoice.invoiceDate = this.dataCache.invoiceDate;
                    this.invoice.invoiceNumber = this.dataCache.invoiceNumber;
                    this.invoice.currency = this.dataCache.currency;
                    this.invoice.total = this.dataCache.amount;
                    this.invoice.exchangeRate = this.dataCache.exchangeRate;
                    
                    this.amounts= {
                        purchaseTotal: 0.00,
                        invoicedTotal: 0.00,
                        invoicedSum: 0.00,
                        creditTotal: 0.00,
                        currencyPO: 'USD',
                        canInvoice: true,
                        isSameCurrency: true,
                        amountExceeded: false
                    };
                }
            }
        },
        update() {
            let $this = this;
            let canUpdate = true;
            let invoiceType = 'NORMAL';

            if (!$this.invoice.requiresXmlFile) {
                canUpdate = $this.validateForm();
                invoiceType = 'WITHOUT_XML';
            }
            
            if (canUpdate) {
                this.isLoading = true;
                
                this.$swal({
                    title: `¡${ $this.$t('attention') }!`,
                    text: $this.$t('msgActionInvoice').replace('ACTION', $this.$t('update').toLowerCase()),
                    icon: 'warning',
                    iconColor: '#bf0d0d',
                    cancelButtonText: $this.$t('cancel'),
                    confirmButtonColor: '#3fbf0d',
                    confirmButtonText: $this.$t('yes')
                }).then(confirm => {
                    if (confirm.isConfirmed) {
                        let formData = new FormData();
                        
                        formData.append('invoiceType', invoiceType);
                        formData.append('user', JSON.stringify(this.user));
                        formData.append('pdfFile', this.file);
                        formData.append('notes', this.notes);
                        
                        if (invoiceType === 'WITHOUT_XML') {
                            formData.append('invoiceDate', $this.invoice.invoiceDate);
                            formData.append('invoiceNumber', $this.invoice.invoiceNumber);
                            formData.append('currency', $this.invoice.currencyId);
                            formData.append('amount', $this.invoice.total);
                            formData.append('exchangeRate', $this.invoice.exchangeRate);
                        }
                        
                        this.axios.post(invoiceUpdate(this.invoiceId), formData).then(response => {
                            if (response.data.code === 'OK') {
                                this.$bvToast.toast(($this.$t('updateSuccess') + '!'), {
                                    title: '',
                                    variant: 'success'
                                });
                                
                                this.isLoading = false;
                                
                                this.isEditing = false;
                                this.invoice.pdfFile = this.file.name;
                                this.invoice.notes = this.notes === '' ? this.$t('noRecord') : this.notes;
                                this.notes = '';
                                this.file = [];
                                this.invoice.status = response.data.status;
                                this.invoice.pdfFileEncryptedName = response.data.pdfFileEncryptedName;
                            } else if (response.data.message) {
                                if (response.data.message === 'INVOICE_REGISTERED') {
                                    this.$bvToast.toast($this.$t('invoiceRegistered'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else {
                                    this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                }
                                
                                this.isLoading = false;
                            } else {
                                this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                                    title: '',
                                    variant: 'danger'
                                });
                                
                                this.isLoading = false;
                            }
                        }).catch(() => {
                            this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                                title: '',
                                variant: 'danger'
                            });
                            
                            this.isLoading = false;
                        });
                    } else {
                        this.isLoading = false;
                    }
                });
            }
        },
        downloadXmlPdfF(encryptedName, fileName) {
            let fileUrl = downloadFile(encryptedName);
            
            this.axios.get(fileUrl, { responseType: 'blob' }).then(async response => {
                downloadAttachment(response, fileName);
            });
        },
        validatePurchaseOrderCode() {
            let $this = this;
            this.amounts= {
                purchaseTotal: 0.00,
                invoicedTotal: 0.00,
                invoicedSum: 0.00,
                creditTotal: 0.00,
                currencyPO: 'USD',
                canInvoice: true,
                isSameCurrency: true,
                amountExceeded: false
            };
            
            this.isLoading = true;
            
            this.axios.post(validatePurchaseOrder(), {
                validationCode: $this.invoice.purchaseOrder,
                type: 'INVOICING',
                supplierId: $this.user.supplierId
            }).then(response => {
                $this.isLoading = false;
                
                if (response.data.code === 'OK') {
                    if (response.data.isValid === true) {
                        if (response.data.POTotal) {
                            $this.amounts.purchaseTotal = parseFloat(response.data.POTotal);
                        }
                        
                        if (response.data.invoicedTotal) {
                            $this.amounts.invoicedTotal = parseFloat(response.data.invoicedTotal);
                        }
                        
                        if (response.data.currencyPO) {
                            $this.amounts.currencyPO = response.data.currencyPO;
                        }
                        
                        if (response.data.POTotal && response.data.invoicedTotal) {
                            
                            if (this.amounts.invoicedTotal > 0) {
                                let intPOTotal = $this.amounts.purchaseTotal;
                                let intInvoiceTotal = $this.amounts.invoicedTotal;
                                
                                if (intInvoiceTotal === intPOTotal) {
                                    $this.amounts.canInvoice = false;
                                } else if (intInvoiceTotal > intPOTotal) {
                                    $this.amounts.canInvoice = false;
                                } else {
                                    $this.amounts.canInvoice = true;
                                }
                            } else {
                                $this.amounts.canInvoice = true;
                            }
                        }
                        
                        $this.invoice.validationStatus = 'VALID';
                        $this.invoice.validationCodeValidated = $this.invoice.validationCode;
                    } else {
                        $this.invoice.validationStatus = 'INVALID';
                        $this.invoice.validationCodeValidated = '';
                    }
                } else {
                    this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                        title: '',
                        variant: 'danger'
                    });
                }
            }).catch(() => {
                this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                    title: '',
                    variant: 'danger'
                });
                
                $this.isLoading = false;
            });
        },
        initSelect2() {
            let $currency = $('#currency-select');
            let $this = this;
            
            $currency.select2({
                placeholder: $this.$t('currency'),
                language: {
                    noResults: () => $this.$t('noResultsFound')
                },
                ajax: {
                    url: currencyList(),
                    dataType: 'json',
                    method: 'GET',
                    data: function(params) {
                        return {
                            searchWord: params.term
                        };
                    },
                    processResults: function(data) {
                        return { results: data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult: function(currency) {
                    if (currency.loading) {
                        return $this.$t('searching') + "... <i class='fa fa-spinner fa-pulse fa-fw pull-right'></i>";
                    }
                    
                    return currency.code || currency.text;
                },
                templateSelection: function(currency) {
                    if (currency.code === undefined && currency.text === undefined) {
                        return '';
                    }
                    
                    return currency.code || currency.text;
                }
            }).on('select2:select', e => {
                this.invoice.currency = e.params.data.code;
                this.invoice.currencyId = e.params.data.id;
                this.validateFields();
            });
            
            if (this.invoice.currency) {
                this.axios.get(currencyList()).then(response => {
                    let currencySelected = response.data.find(currency => currency.code === $this.invoice.currency);
                    
                    if (currencySelected) {
                        $currency.empty().append(new Option(currencySelected.code, currencySelected.id, true, true)).trigger('change');
                        $this.validateFields();
                    }
                });
            }
        },
        validateFields() {
            let $this = this;
            
            if ($this.invoice.purchaseOrder !== '') {
                if ($this.amounts.currencyPO === $this.invoice.currency) {
                    $this.amounts.isSameCurrency = true;
                } else {
                    $this.amounts.isSameCurrency = false;
                }
                
                if (parseFloat($this.invoice.total) >= 0) {
                    let invoiceAmount = this.exchangeRate($this.invoice.total);
                    let sumInvoiced = $this.amounts.invoicedTotal + parseFloat(invoiceAmount);
                    $this.amounts.invoicedSum = sumInvoiced;
                    
                    let maxPOTotal = $this.amounts.purchaseTotal + parseFloat(`${ constants.INTEGER_ACCEPTED_RANGE_WHEN_INVOICING }.${ constants.DECIMAL_ACCEPTED_RANGE_WHEN_INVOICING }`);
                    let intPOTotal = Math.floor($this.amounts.purchaseTotal);
                    let intInvoiceTotal = Math.floor(sumInvoiced);
                    
                    if (intInvoiceTotal === intPOTotal) {
                        $this.amounts.canInvoice = true;
                        $this.amounts.amountExceeded = false;
                    } else if (intInvoiceTotal > intPOTotal) {
                        if ((sumInvoiced >= $this.amounts.purchaseTotal) && (sumInvoiced <= maxPOTotal)) {
                            $this.amounts.canInvoice = true;
                            $this.amounts.amountExceeded = false;
                        } else {
                            $this.amounts.canInvoice = false;
                            $this.amounts.amountExceeded = true;
                        }
                    } else if (intInvoiceTotal < intPOTotal) {
                        $this.amounts.canInvoice = true;
                        $this.amounts.amountExceeded = false;
                    }
                }
            }
        },
        validateForm() {
            let $this = this;
            let valid = true;
            
            if ($this.invoice.invoiceDate === '') {
                $this.isValid.invoiceDate = false;
                valid = false;
            } else {
                $this.isValid.invoiceDate = true;
            }
            
            if ($this.invoice.invoiceNumber === '') {
                $this.isValid.invoiceNumber = false;
                valid = false;
            } else {
                $this.isValid.invoiceNumber = true;
            }
            
            if ($this.invoice.total === '' || isNaN($this.invoice.total)) {
                $this.isValid.amount = false;
                valid = false;
            } else {
                $this.isValid.amount = true;
            }
            
            if ($this.invoice.currencyId === '' && $this.invoice.currency === '') {
                $this.isValid.currency = false;
                valid = false;
            } else {
                $this.isValid.currency = true;
            }
            
            if ($this.invoice.exchangeRate === '' || isNaN($this.invoice.exchangeRate)) {
                $this.isValid.exchangeRate = false;
                valid = false;
            } else {
                if ($this.invoice.exchangeRate <= 0) {
                    $this.isValid.exchangeRate = false;
                    valid = false;
                } else {
                    $this.isValid.exchangeRate = true;
                }
            }
            
            return valid;
        },
        exchangeRate(total) {
            if (!this.amounts.isSameCurrency) {
                if (this.amounts.currencyPO === 'MXN') {
                    total = total * (this.invoice.exchangeRate ? this.invoice.exchangeRate : 1);
                } else if (this.amounts.currencyPO === 'USD') {
                    total = total / (this.invoice.exchangeRate ? this.invoice.exchangeRate : 1);
                }
            }
            
            return total;
        }
    },
    watch: {
    
    },
    computed: {
    
    }
};
</script>